import styled from 'styled-components';
import {coresPadrao} from '../../breakpoints';

// #################################################
export const Body = styled.div`
display: flex;
width: 100%;
min-height: 20px;
max-width: 400px;
overflow: hidden;
justify-content: center;
`
export const BU = styled.button`
color: ${props => props.pageN? "white" : "black"} ; ;
font-size:11.5px ;
margin:0 3px 3px 3px ;
cursor:pointer ;
background-color: ${props => props.pageN? "#3881ff" : "white"} ;
`
//-----------
