import styled from 'styled-components';
import {breakpoints} from '../../breakpoints';

export const App = styled.div`
  display: flex ;
  position: relative ;
  flex-direction: column;
  height: 100vh;
  width: 100vw ;
  background-color: #282c34;
  overflow: hidden ; 
  font-size: clamp(10px, 3vw, 12px);
  
  
  .fundoBloque{
  display: ${props=>props.fundoBloque?'flex':'none'} ;
  width: 100vw;
  height: 100vh; 
  background-color: rgba(20,20,20,0.5) ;
  position: fixed ;
  z-index: 1 ;
};
`

export const body = styled.div`
display: flex;
font-size: 12px;
position: relative ;

@media ${breakpoints.sm} {
  flex-direction: column ;
  margin-top: 30px;
  
};

button{
  border:none;
};

`
export const bodyTitulo = styled.div`
 display:flex;
 flex-direction: column ;
 width:100vw ;
 min-width:150px;
 color:white ;
 border-radius:3px;
 align-items: center ;

 .titulo{
  display: flex ;
  width: 100% ;
  color:white ;

}
.tituloUser{
  display: flex ;
  flex: 1;
  align-items: center ;
  justify-content: center ;
  width: 100% ;
}

 `
 export const bodyLista = styled.div`
 display: flex ;
 flex-direction: column ;
 width: 100% ;
 margin-bottom: 10px ;
 height: 71vh ;
 overflow: auto ;
 
 `
// -----------------------------
export const body2 = styled.div` 
display: flex;
width:100vw ;
flex-direction: column ;
color: white;

@media ${breakpoints.sm} {
  align-items: center ;
  margin: 0 ;
}

`  

// --------------LISTA-----------------------------
export const ContainerLista = styled.div`
display: flex;
flex-direction: column ;
margin-left: clamp(110px, 12vw, 150px);
margin-top:60px ;
width:88vw ;

@media ${breakpoints.sm} {
  width:100%;
  justify-content: center ;
  align-items:center ;
  margin: 60px 0 0 0;
}


`
// -----------------------------
export const containerPesq = styled.div` 
display: flex;
flex-direction: column ;
width:100% ;

@media ${breakpoints.sm} {
  width:95% ;
}
`  
// -----------------------------
export const ModalConteine = styled.div`
display: flex ;
flex-direction: column ;
width:90vw;

@media ${breakpoints.sm}{
  width:95vw;
}
@media (min-height: 500px){
  height:60vh;
}
@media (min-height: 700px){
  height:70vh;
}
@media (min-height: 800px) {
  height:75vh;
}
@media (min-height: 900px) {
  height:80vh;
}
@media (min-height: 1050px) {
  height:85vh;
}

.detalhes{
  display: flex ;
  flex: 1 ;
  flex-direction: column ;
}
`
//-----------------------------------
export const bodyColunas = styled.div`
  display:flex ;
  overflow:auto ;

  @media ${breakpoints.sm} {
    justify-content: center ;
  }
`

// -----------------------------------
export const divGrid = styled.div`
display: grid ;
grid-template-columns: ${props=>props.listaGrid === 1? "1fr" : props.listaGrid === 2? "1fr 1fr" : "1fr 1fr 1fr"};
/* grid-template-rows: auto auto ; */
gap: 5px ;
width:98% ;
margin-bottom: 5px;
overflow-y: auto ;

.colunaA, .colunaP, .colunaC{
  grid-row: span 10;
}

/* ----- */
@media ${breakpoints.bg} {
  .colunaP{
    grid-column: 2 ;
    grid-row: 1 ;
  }
  .colunaC{
    grid-column: ${props=>props.listaGrid === 1? 1 : 2};
    grid-row: ${props=>props.listaGrid === 1? 1 : props.listaGrid === 2? 1 : 2};
  }
};

/* ------ */
@media ${breakpoints.md} {
  grid-template-columns: 1fr ;

  .colunaA{
    grid-column: 1 ;
    grid-row: 1 ;
  }
  .colunaP{
    grid-column: 1 ;
    grid-row: ${props=>props.listaGrid === 1? 1 : 2};
  }
  .colunaC{
    grid-column: 1 ;
    grid-row: ${props=>props.listaGrid === 1? 1 : props.listaGrid === 2? 2 : 3};
  }
};

` 
// ------------------------------
export const Modal = styled.div`
display: flex ;
overflow:auto ;

@media ${breakpoints.sm} {
  justify-content:center ;
}
`
// ----------------------------------
export const div3 = styled.div` 
display:flex;
flex:1;
flex-direction: column;
align-items: center;
margin:5px;

.fechar{
  display:flex;
    color:white;
    border-radius:3px;
    width:20px;
    height:20px;
    font-size:13px;
    justify-content:center;
    align-items:center;
    padding-bottom:1px;
    position:relative;
    left:140px;
    cursor: pointer;
    font-weight:bold;

    &:hover{
    background-color: #f0433d;
  }

}

`

export const boxBut = styled.div`

button{
  background:none;
  cursor:pointer;
  outline:0;
  

  &:hover{
    background: none;
    opacity: 0.5;
}
}
`
export const Salvar = styled.div`
display:flex;
height:25px;
margin:5px;
justify-content:center;

button{
  background-color:#00D594;
  width:80px;
  font-size: 12px;
  border:none;
  border-radius:5px;
  color:white;

&:hover{
  background-color:#5656F7;
}
}

`
//-------------------Resultado-------------------------------
export const bodyresult = styled.div`
display: flex ;
width:95% ;
align-items: start ;


.abrirModal{
  display:flex;
  max-width: 90px ;
  min-width: 90px;
  height:17px;
  align-items: center ;
  justify-content: center ;
  background-color:#3881ff;
  border-radius:1px;
  padding-bottom: 2px;
  font-size: 12px ;
  
  cursor:pointer ;

}

`
