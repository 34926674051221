import React, { useContext, useEffect, useState, useRef } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import editar from '../../midia/editar_branco.svg';
import coin from '../../midia/coin_red.svg';
import api from '../../services/api';
import {format} from 'date-fns';
import {AlertComp} from '../../componentes/alerte/index';
import {ApiEvento} from '../../services/FiltrosApi/eventoApi';
import {SetFunctions} from '../../componentes/Functions';

//-----------------------------------------------
export const DetalheEvento = (props) => {
    const {state, dispatch} = useContext(Context);
    const {evento, terceiro} = state.evento;
    const {parametroInit, playmodal, playLista, listaPesquisa} = state.parametros;
    const {playListaPagto} = playLista;

    const[alertDelete, setAletDelete]=useState(false);
    const {AtualizarIdEvento, AtualizarPartic, AtualizarReparo} = ApiEvento();
    const {SetEvento} = SetFunctions();
   
    // -----------------------------------------
    const SetPlayLista = (key, value) => {
        dispatch({
            type: 'set_playLista',
            payload: {...playLista, [key]: value}
        });
    };

    //-----------------------------------------
    function FecharAlerte(){
        setAletDelete(false)
    };

    //------------------------------------------
    function EditaEvento(){
        dispatch({
        type:'SET_PLAYMODAL',
        payload: {
            ...playmodal, 
            playModalEven: playmodal.playModalEven? false:true,
            playModalPagamento: false,
            playModalEditar:true,
            playModalDetalhesEven: false,
        }
        });

        dispatch({
            type:'SET_parametroInit',
            payload: {
                ...parametroInit, 
                editModal:true,
                detalhesLista:false
            }
        });
    };
 
    // --------------------
    function addPagamento(){
        dispatch({
            type:'SET_PLAYMODAL',
            payload: {
                ...playmodal, 
                playModalPagamento: playmodal.playModalPagamento? false : true,
                playModalEven: false,
                playModalDetalhesEven: false, 
            }
        });

        dispatch({
            type:'set_playLista',
            payload: {
                ...playLista, 
                playListaPagto:false
            }
        });
    };
    
    //-----------------------------------------------
    async function ExcluirEste(t){
        await api.put(`evento/atualizar/${evento.id_Evento}`,{
            status:'E'
        })
        .then(res => {
            props.PesqEventoCod();
            setAletDelete(false);
        })
    };
    
    // -----------------------------------
    const[ComParticip, setComPart] = useState(false);
    const[FoiColisao, setColisao] = useState(false);
    
    useEffect(() => {
        const ComPart = evento.tipoEvento.split("_").filter(e=> e === "part").length > 0;
        setComPart(ComPart);

        const Colisao = /colisao/i.test(evento.tipoEvento);
        setColisao(Colisao);
    }, [evento.tipoEvento])
    
    // --------------------------------
    const [ListaOrdPag, setListaOrd]=useState([]);
    const [somaTotalPG, setSomaTotalPG] = useState(0);
    const [SelectValor, setSelectValor] = useState(false);
    const [editPart, seteditPart] = useState(false);
    const [editReparo, seteditReparo] = useState(false);

    useEffect(() => {
        //-Ordenar pagamento por data-
        var xx = listaPesquisa.listaPagamentos.sort((a,b)=> (
            new Date(a.dataPG) - new Date(b.dataPG)
        ));
        setListaOrd(xx);

         //-Soma Total--
         const somaX = listaPesquisa.listaPagamentos.map(e=> e.valorPG);
         if(somaX.length>0){
             let somaTotal = 0;
             for(let i = 0; i < somaX.length; i++){
                 somaTotal += somaX[i];
             }
             setSomaTotalPG(somaTotal);
         };

        //  ---------------
        listaPesquisa.listaEvento.map(t=> {
            if(t.dataPart){seteditPart(false)}else{seteditPart(true)};
            if(t.dataReparo){seteditReparo(false)}else{seteditReparo(true)};
        })
        
    }, [listaPesquisa.listaPagamentos, listaPesquisa.listaEvento]);

    // ------------------------------------
    async function AddOrcamento(){
        const orcamento = evento.orcamento.toString();

        await api.put(`/evento/atualizar/${evento.id_Evento}`, {
            orcamento: parseFloat(orcamento.replace(",", "."))
        })
        .then(res => {
            alert("Sucesso!")
            setSelectValor(false)
            SetEvento("orcamento",parseFloat(orcamento.replace(",", ".")))
        })
        .catch(() => alert("erro!!!"))
    };

    // ---Click Dentro e Fora do Componente-----
    const ClickRef = useRef(null);
    const ClickRefPart = useRef(null);
    const ClickRefReparo = useRef(null);
    
    // ------------
    useEffect(() => {
      document.addEventListener("click", EventoDeClick, true);
    }, [])

    function EventoDeClick(e){
        if(!ClickRef.current?.contains(e.target)){
            setSelectValor(false);
        };
        if(!ClickRefPart.current?.contains(e.target)){
            seteditPart(false);
        };
        if(!ClickRefReparo.current?.contains(e.target)){
            seteditReparo(false);
        };
    };
    

//###################################################################
    return(
        <S.bodyresult>
            {alertDelete && 
            <AlertComp
            alertDescrit={'Deseja mesmo Excluir?'}
            seSim={ExcluirEste}
            seNao={FecharAlerte}
            />
            }

            <S.detalhes>
                <div style={{display:"flex", 'width':"100%", "flexDirection":"column"}}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div style={{display:"flex", flexDirection:"column", "width":"100%"}}>
                            <div className='listaDetalhe'>
                                <div className='lista'>
                                    <span className='titulo'>Data Evento:</span>
                                    <span>{evento.dataEvento ? format(new Date(evento.dataEvento), 'dd/MM/yyyy'):""}</span>
                                </div>
                                <div className='lista'>
                                    <span className='titulo'>Associado:</span>
                                    <span>{evento?.nomeCliente}</span>
                                    <span>Celular</span>
                                </div>
                                <div className='lista'>
                                    <span className='titulo'>Evento: </span>
                                    <span style={{textTransform:"uppercase"}}>BO: { evento?.ocorrenciaBO}</span>
                                    <span>{evento.tipoEvento === "PT"? "Perca Total" : evento.tipoEvento}</span>
                                </div>
                            </div>
                            
                            <div className='listaDetalhe' style={{marginTop:"5px"}}>
                                <div className='lista'>
                                    <span className='titulo'>Veiculo: </span>
                                    <span>{evento?.placa}</span>
                                    <span>Modelo{evento?.tipo}</span>
                                    <span>Valor Fipe</span>
                                </div>

                                <div className='lista'>
                                    <span className='titulo'>Terceiro:</span>
                                    <span style={{textTransform:"uppercase"}}>{terceiro?.placa}</span>
                                    <span style={{textTransform:"uppercase"}}>{terceiro?.modelo}</span>  
                                    <span>{terceiro?.valorFipe}</span>  
                                </div>  
                                <div className='lista' style={{textTransform:"lowercase"}}>
                                    <span className='titulo'>Descrição::</span>
                                    <span>{evento?.descricao}</span>
                                </div>   

                                <div className='lista'> 
                                </div>               
                            </div>
                        </div>
                        
                        <div style={{display:'flex', 'flexDirection':"column" , justifyContent:"space-around"}}>
                            <img 
                            className='icone'
                            src={editar} 
                            onClick={()=>EditaEvento()} 
                            alt="icone"
                            />
                            
                            {FoiColisao &&
                            <img 
                            style={{width:"20px", height:"20px"}}
                            className='icone'
                            src={coin} 
                            onClick={()=>addPagamento()} 
                            alt="icone"
                            />
                            }
                        </div>
                    </div>
                    
                    {/*----Com PARTICIPAÇÃO------*/}
                    {ComParticip && <>
                    <div style={{borderBottom: "1px solid #3881ff"}} />
                    <div ref={ClickRefPart} style={{display:"flex", "alignItems":"end", padding:"5px 0"}}>
                        {editPart ?
                        <>
                        <div className='lista'>
                            <span className='titulo'>Data Participação: </span>
                            <input 
                            type="date"
                            placeholder='Data Participação' 
                            value={evento.dataPart? format(new Date(evento.dataPart), 'yyyy-MM-dd'):""}
                            onChange={e=>SetEvento('dataPart',e.target.value)}
                            />
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Valor Participação: </span>
                            <input 
                            type="number"
                            placeholder='valor' 
                            value={evento.valorPart}
                            onChange={e=>SetEvento('valorPart',e.target.value)}
                            />
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Observação: </span>
                            <input 
                            type="text"
                            placeholder='detalhes' 
                            value={evento.detalhePart}
                            onChange={e=>SetEvento('detalhePart',e.target.value)}
                            />
                        </div>
                        </>
                        :
                        <>
                        
                        <div className='lista'>
                            <span className='titulo'>Data Participação: </span>
                            <span>{evento.dataPart ? format(new Date(evento.dataPart ), 'dd/MM/yyyy'):""}</span>  
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Valor Participação: </span>
                            <span>{parseFloat(evento.valorPart ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>  
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Observação: </span>
                            <span>{evento.detalhePart}</span>  
                        </div>
                        </>
                        }
                        
                        {editPart ?
                        <button 
                        type='button' 
                        onClick={()=>AtualizarPartic()}>add
                        </button>
                        :
                        <img 
                        className='icone'
                        src={editar} 
                        onClick={()=>seteditPart(true)} 
                        alt="icone"
                        />
                        }
                    </div>
                    </>}
                    
                    {/* -----RESSARCIMENTO-------- */}
                    {!FoiColisao && <>
                    <div style={{borderBottom: "1px solid #3881ff", marginBottom:"5px"}} />

                    <div ref={ClickRefReparo} style={{display:"flex", "alignItems":"end"}}>
                        {editReparo ?
                        <>
                        <div className='lista'>
                            <span className='titulo'>Data Resarcimento: </span>
                            <input 
                            type="date"
                            placeholder='Data Participação' 
                            value={evento.dataReparo}
                            onChange={e=>SetEvento('dataReparo',e.target.value)}
                            />
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Valor Resarcimento: </span>
                            <input 
                            type="number"
                            placeholder='valor' 
                            value={evento.valorReparo}
                            onChange={e=>SetEvento('valorReparo',e.target.value)}
                            />
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Observação: </span>
                            <input 
                            type="text"
                            placeholder='detalhes' 
                            value={evento.detalheReparo}
                            onChange={e=>SetEvento('detalheReparo',e.target.value)}
                            />
                        </div>
                        </>
                        :
                        <>
                        
                        <div className='lista'>
                            <span className='titulo'>Data Resarcimento: </span>
                            <span>{evento.dataReparo ? format(new Date(evento.dataReparo ), 'dd/MM/yyyy'):""}</span>  
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Valor Resarcimento: </span>
                            <span>{parseFloat(evento.valorReparo).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>  
                        </div>
                        <div className='lista'>
                            <span className='titulo'>Observação: </span>
                            <span>{evento.detalheReparo}</span>  
                        </div>
                        </>
                        }
                        
                        {editReparo ?
                        <button 
                        type='button' 
                        onClick={()=>AtualizarReparo()}>add
                        </button>
                        :
                        <img 
                        className='icone'
                        src={editar} 
                        onClick={()=>seteditReparo(true)}
                        alt="icone"
                        />
                        }
                    </div>
                    </>}

                    {/* -------------------- */}
                    {FoiColisao &&
                    <S.ListaPag>
                        <div style={{display:"flex", justifyContent:"space-between", color:"#00D594", width:"100%", padding:"5px 0"}}>
                            <div ref={ClickRef} style={{display:"flex", flexDirection:"row"}}> 
                                <span>Orçamento:</span>
                                {SelectValor ?
                                <>
                                <input
                                style={{backgroundColor:"#30353F", borderRadius:"3px", marginLeft:"5px"}}
                                type="text"
                                autoFocus
                                value={evento.orcamento} 
                                onChange={(e)=> SetEvento("orcamento", e.target.value)}
                                />

                                <button 
                                style={{marginLeft:"5px"}}
                                onClick={()=>AddOrcamento()}
                                >
                                    add
                                </button>
                                </>
                                :
                                <span 
                                className='botaoTotal' 
                                style={{color:"yellow"}} 
                                onClick={()=> setSelectValor(true)}
                                > 
                                    {parseFloat(evento.orcamento).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} 
                                </span>
                                }
                            </div>

                            <span>Pago:
                                <span 
                                className='botaoTotal' 
                                style={{color:"orange"}} 
                                onClick={()=>SetPlayLista("playListaPagto", playListaPagto? false : true)}
                                > 
                                    {parseFloat(somaTotalPG).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} 
                                </span>
                            </span>

                            <span>Pendente:
                                <span style={{color:"red", marginLeft:"3px"}}> 
                                    {parseFloat(evento.orcamento - somaTotalPG).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} 
                                </span>
                            </span>
                        </div>
                        {playLista.playListaPagto && 
                        <div style={{display:"flex", width:"100%", flexDirection:"column", maxHeight:"150px", overflow:"auto"}}>
                        {ListaOrdPag.map((p,k)=>(
                        <div className='listaPg' key={k}>
                            <span style={{paddingLeft:"3px"}}>{evento.dataPart ? format(new Date(p.dataPG), 'dd/MM/yyyy'):""}</span> 
                            <span>{p.tipoPG}</span>  
                            <span>{p.nomeItem}</span>  
                            <span>{parseFloat(p.valorPG).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>  
                        </div>
                        ))}
                        </div>}
                    </S.ListaPag>
                    }
                </div>
                
            </S.detalhes>
        </S.bodyresult>
    
)}