import styled from 'styled-components';
import {breakpoints} from '../../breakpoints';

export const App = styled.div`
  display: flex ;
  position: relative ;
  flex-direction: column;
  height: 100vh;
  width: 100vw ;
  background-color: #282c34;
  
  
  .fundoBloque{
  display: ${props=>props.fundoBloque?'flex':'none'} ;
  width: 100vw;
  height: 92vh; 
  background-color: rgba(20,20,20,0.8) ;
  position: absolute ;
  z-index: 1 ;
  margin-top: 40px ;
  justify-content: center ;
  align-items: center ;

  };

  .BotaoPagina{
    display: flex ;
    width: 30px ;
    height: 30px ;
    color: white ;
    font-weight:600 ;
    align-items: center ;
    justify-content: center ;
    border-radius: 3px ;
    margin-top: 20px ;
    cursor: pointer;
  }
  
  
`

// -----------------------------
export const body = styled.div`
display: flex;
font-size: 12px;
margin-top:50px ;
justify-content: space-between ;

@media ${breakpoints.sm} {
  flex-direction: column ;
  
};

button{
  border:none;
  color:white ;
};

`
export const bodyTitulo = styled.div`
 display:flex;
 flex-direction: column ;
 width:100vw ;
 min-width:150px;
 color:white ;
 border-radius:3px;
 align-items: center ;

 .titulo{
  display: flex ;
  width: 100% ;
  color:white ;

}
.tituloUser{
  display: flex ;
  flex: 1;
  align-items: center ;
  justify-content: center ;
  width: 100% ;
}

 `
 export const bodyLista = styled.div`
 display: flex ;
 flex-direction: column ;
 width: 100% ;
 margin-bottom: 10px ;
 height: 71vh ;
 overflow: auto ;
 
 `
//  ------------------------------
export const BarraLateral = styled.div`
display: flex ;
width: 13%;
min-width: 130px;
min-height: 92vh;
background-color:#262626;
justify-content: center ;

@media ${breakpoints.sm} {
  height: 35px ;
  min-height: 1vh;
  width:100% ;
}
`
export const pesquisa = styled.div` 
display: flex;
width: 100%;
margin-left: 10px;
flex-direction: column ;
color: white;

@media ${breakpoints.sm} {
  align-items: center ;
  margin: 0 ;
}

`
export const Etapas = styled.div`
display: flex ;
gap: 10px ;
margin-top: 20px ;
width:95vw;
max-width:500px ;
justify-content: end ;
position: fixed ;
top: 330px ;

`  
// ------------------------------
export const Button = styled.button`
height: 20px;
border-radius:3px ;
font-weight:500 ;
min-width: 80px ;
border: none ;
margin: 5px ;
cursor: pointer;

`
export const containerPesq = styled.div` 
display: flex;
flex-direction: column ;

`  

// --------------LISTA-----------------------------------------------

export const ContainerLista = styled.div`
display: flex;
flex-direction: column ;
width:95% ;

`
export const ListaEvento = styled.div` 
display: flex;

@media ${breakpoints.sm} {
  justify-content: center ;
}

.detalhes{
  display: flex ;
  flex-direction: column ;
}

`  
export const divGrid = styled.div`
display: grid ;
grid-template-columns: ${props=>props.listaUm? "1fr" : "1fr 1fr 1fr"}  ;
grid-gap: 5px ;
overflow:auto ;
max-height: 72vh ;

.coluna1{
  display: grid ;
}


@media ${breakpoints.bg} {
  grid-template-columns: ${props=>props.listaUm? "1fr" : "1fr 1fr"};
  .coluna3{
    display: grid ;
    grid-column: 2 ;
  }
};

@media ${breakpoints.md} {
  grid-template-columns: 1fr ;
  .coluna3{
    display: grid ;
    grid-column: 1 ;
  }
  .coluna2{
    display: grid ;
    grid-column: 1 ;
  }
}

` 

export const div3 = styled.div` 
display:flex;
flex:1;
flex-direction: column;
align-items: center;
margin:5px;

.fechar{
  display:flex;
    color:white;
    border-radius:3px;
    width:20px;
    height:20px;
    font-size:13px;
    justify-content:center;
    align-items:center;
    padding-bottom:1px;
    position:relative;
    left:140px;
    cursor: pointer;
    font-weight:bold;

    &:hover{
    background-color: #f0433d;
  }

}

`

export const boxBut = styled.div`

button{
  background:none;
  cursor:pointer;
  outline:0;
  

  &:hover{
    background: none;
    opacity: 0.5;
}
}
`
export const box = styled.div`
display:flex;
flex-direction:row;
margin:5px;
border-bottom:1px solid #3881ff;
font-size:14px;

select{
  display:flex;
  border-radius:3px;
  color: white;
  background-color:#3881ff;
  border:none;
  outline:0;
  margin:5px;
}
span{
  color: #00D594;
}
.spa{
  color:white;
}

input{
border:none;
background:none;
font-size:14px;
color: white;
outline:0;
}
`
export const div4 = styled.div` 
display:flex;
justify-content:space-between;
`
export const div5 = styled.div` 
display:flex;
padding:3px;
border-radius:5px;
align-items:center;
`
export const Exc = styled.div`
display:flex;
width:70px;
background-color:#f0433d;
padding:3px;
border-radius:5px;
justify-content:center;
`
export const Salvar = styled.div`
display:flex;
height:25px;
margin:5px;
justify-content:center;

button{
  background-color:#00D594;
  width:80px;
  font-size: 12px;
  border:none;
  border-radius:5px;
  color:white;

&:hover{
  background-color:#5656F7;
}
}

`
//-------------------Resultado-------------------------------
export const bodyresult = styled.div`


.abrirModal{
  display:flex;
  max-width: 90px ;
  min-width: 90px;
  height:17px;
  align-items: center ;
  justify-content: center ;
  background-color:#3881ff;
  border-radius:1px;
  padding-bottom: 2px;
  font-size: 12px ;
  
  cursor:pointer ;

}

`
