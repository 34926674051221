import styled from 'styled-components';
import {coresPadrao} from '../../breakpoints';


export const Footer = styled.div`
height:30px;
width:100vw;
display:flex;
align-items:center;
justify-content:center;
background-color: ${coresPadrao.corFundoPreto} ;
font-size:10px;
color: #F2F2F2;
border-top: 10px solid #3881ff;
position:fixed;
bottom:0;
z-index:1;
margin:0 ;
`