import React, {useEffect, useState, useContext, useRef} from 'react';
import * as S from './styles';
import {Context} from '../../services/contexts/StateContext';
import MaskedInput from 'react-text-mask';
import {format} from 'date-fns';
// -----------------------
import {PesquisaPadrao} from '../../componentes/Pesquisas/PesquisaPadrao';
import {Add_Arquivos} from '../../componentes/Cadastro/Add_Arquivos';
import {ListaItemBD} from '../ListarPesquisas/listaItemBD';
import {Api_Veiculos} from '../../services/FiltrosApi/veiculos_api';
import {ApiEvento} from '../../services/FiltrosApi/eventoApi';
import {Functions, FunctModal, SetFunctions} from '../../componentes/Functions';
import icon_addArquivo from '../../midia/add-upload.svg';
// ----------------------------------------
function ModalEvento(props) { 
    const {state, dispatch} = useContext(Context);
    const {evento, terceiro} = state.evento;
    const {veiculo} = state.veiculo;
    const {cliente} = state.cliente;
    const {parametroInit, playmodal, listaPesquisa} = state.parametros;
    const {editModal, atualizarModal, disabledEven} = parametroInit; 
    const {playModalDetalhesEven} = playmodal;

    const [teveTerceiro, setTerceiro]=useState(false);
    const {PesqVeiculoPlaca} = Api_Veiculos();
    const {AddEvento, AddFornecedor, PesqFornecedor} = ApiEvento();
    const {PlayEvento, EditarSelect, Play_Modal_Add} = Functions();
    const {FecharModal} = FunctModal();
    const {SetEvento} = SetFunctions();

    const {SelectFile, AddArquivos} = Add_Arquivos();

    const FornecedorRef = useRef(null);
    const PlacaVeiculoRef = useRef(null);
        
    //----------------------------------------
    function funcCheckBox(e){
        setTerceiro(e.target.checked)
    };
    
    //----------------------------------------
    useEffect(() => {
    if(terceiro.chassi || terceiro.placa){
        setTerceiro(true)
    }
    }, [terceiro.chassi, terceiro.placa]);

    // ---------------------------------------
    const SetEventoTerc = (key, value) => {
        dispatch({
            type: 'set_Terceiro',
            payload: {...terceiro, [key]: value}
            });
    };
    //-------Atualizar-----------------------------
    function Atualizar(){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                atualizarModal:true,
                editModal:false, 
                disabledEven:false,                 
            }
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal, 
                playModalDetalhesEven:false,
                
            }
        });
    };

    // ----------------------------
    const [novoFornecedor, setnovoFornecedor] = useState(false);

    useEffect(() => {
        if(listaPesquisa.nomeItem.length>0)setnovoFornecedor(false);

        if(listaPesquisa.nomeItem.length === 7 && !veiculo.id_Veiculo && playmodal.playModalEven){
            //-Pesquisar ao digitar Placa-
            PesqVeiculoPlaca(listaPesquisa.nomeItem);
        }else{
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{
                    ...listaPesquisa, 
                    alertaNovo:false,
                    listaVeiculo:[],
                    listaFornecedor:[],
                }
            });
        };
    }, [listaPesquisa.nomeItem]);

    // ----Abrir Novo Fornecedor----------
    function NovoFornecedor(){
        setnovoFornecedor(true);

        dispatch({
            type: 'set_ListaPesquisa',
            payload:{
                ...listaPesquisa, 
                nomeItem:"",
                selectItem:'',
                alertaNovo:false
            }
        });

        if(!evento.id_Fornecedor && !playmodal.playModalEditar){
            dispatch({type: "set_limparEvento"});
        };
    };

    // -----------------------------------
    const [cpfFormat, setcpfFormat]=useState('');
    const [referenciaRef, setReferenciaRef] = useState("");

    //----Retirando Pontos do cpf--------
    useEffect(() => {
    let value = cpfFormat;
    if(cpfFormat){
        let rr = value.replace(/\D/g,''); //.replace(/\D/gim, '')
        SetEvento('cnpjForne', rr)
        }
    }, [cpfFormat])

    // ----------------------
    useEffect(() => {
        setcpfFormat(evento.cnpjForne)
    }, [evento.cnpjForne])
    
    // ----------------------
    useEffect(() => {
        document.addEventListener("click", EventoDeClick);
      }, [])

    // ----------------------
    function EventoDeClick(e){
        if(FornecedorRef.current?.contains(e.target)){
            setReferenciaRef("RefFornecedor");
        };
        
        if(PlacaVeiculoRef.current?.contains(e.target)){
            setReferenciaRef("veiculoPlaca");
        };
    };
    // ----------------------

//###################################################################################
  return (
    <S.body 
    detalhesLista={parametroInit.detalhesLista}
    editModal={editModal}
    >
        <S.Container>
            <S.div3>
                <div className='titulo'>
                    <div className={'tituloUser'}>
                        <span>{playModalDetalhesEven ? 'Detalhes Evento': editModal || atualizarModal? 'EDITAR Evento':'Novo Evento'}</span>
                    </div>

                    <div 
                    className='fechar'
                    onClick={()=>FecharModal()}>X
                    </div>
                </div>
            </S.div3>

            {/* ---------PESQUISA PLACA---------- */}
            <S.div3>
                {!veiculo.id_Veiculo && !playmodal.playModalEditar ?
                <>
                    <S.divPesq ref={PlacaVeiculoRef}>
                        <PesquisaPadrao
                        refPlaca = {true}
                        lupa={props.lupa}
                        titulo="Buscar Placa"
                        placeholder="placa"
                        playModal_Add={Play_Modal_Add}
                        Pesquisar={PesqVeiculoPlaca}
                        PesqDentroModal={true}
                        playBotao={false}
                        Lupa={true}
                        />
                    </S.divPesq>
                    
                    {listaPesquisa.nomeItem &&
                    <S.divListaBD>
                        <ListaItemBD
                        NovoCadastro={PlayEvento}
                        listaItem={listaPesquisa.listaVeiculo}
                        referenciaRef={referenciaRef}
                        />
                    </S.divListaBD>
                    } 
                </>
                :
                veiculo.id_Veiculo && !playmodal.playModalEditar &&
                <>
                <S.box1>
                    <div
                    ref={PlacaVeiculoRef}
                    className='EditarSelect' 
                    onClick={()=>EditarSelect("eventoPlaca")}
                    >
                        <span>PLACA:</span>
                        <div className='intemSelect'>
                            {veiculo.placa}
                        </div>
                    </div>
                </S.box1>

                <S.box1>
                    <div className='listaFlexCol'>
                        <span>Modelo:</span>
                        <div className='intem'>
                            {veiculo.modelo}
                        </div>
                    </div>
                    <div className='listaFlexCol'>
                        <span>Cliente:</span>
                        <div className='intem'>
                            {veiculo.nomeCliente ? veiculo.nomeCliente : cliente.nome}
                        </div>
                    </div>
                </S.box1>
                </>}
            </S.div3>

            {/* ---------FORNECEDOR---------- */}
            <S.div3>
                {veiculo.id_Veiculo && !evento.id_Fornecedor && !editModal &&
                <>
                <S.divPesq ref={FornecedorRef}>
                    <PesquisaPadrao
                    refNomeCPF={true}
                    lupa={props.lupa}
                    titulo="Buscar Fornecedor"
                    placeholder="nome ou cnpj"
                    playModal_Add={props.playModal_Add}
                    Pesquisar={PesqFornecedor}
                    PesqDentroModal={true}
                    playBotao={false}
                    Lupa={true}
                    /> 
                </S.divPesq>

                {listaPesquisa.nomeItem &&
                <S.divListaBD>
                    <ListaItemBD
                    listaItem={listaPesquisa.listaFornecedor}
                    NovoCadastro={NovoFornecedor}
                    referenciaRef={referenciaRef}
                    />
                </S.divListaBD>
                }
                </>}

                <S.box1>                
                    {veiculo.id_Veiculo && !evento.id_Fornecedor && novoFornecedor ?
                    <>
                        <div className='listaFlexCol' style={{minWidth:"150px"}}>
                            <span style={{color: !playmodal.playModalEditar && "yellow"}}>Novo Fornecedor:</span>
                            <input 
                            disabled={disabledEven}
                            value={evento.fornecedor}
                            className='maiusculo'
                            type='text' 
                            placeholder='nome'
                            onChange={e=>SetEvento('fornecedor', e.target.value)}
                            />
                        </div>
                        
                    {evento.cnpjForne.length <= 11?
                        <div className='listaFlexCol' style={{minWidth:"100px"}}>
                            <span style={{color: !playmodal.playModalEditar && "yellow"}}>CNPJ:</span>
                            <MaskedInput
                            disabled={disabledEven}
                            value={cpfFormat}
                            type='text'
                            placeholder='cpf/cnpj'
                            mask={[/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'-',/\d/, /\d/,/\d/]}
                            onChange={e=>setcpfFormat(e.target.value)}
                            guide={false}
                            />
                        </div>
                        :
                        <div className='listaFlexCol' style={{minWidth:"100px"}}>
                            <span style={{color: !playmodal.playModalEditar && "yellow"}}>CNPJ:</span>
                            <MaskedInput
                            disabled={disabledEven}
                            value={cpfFormat}
                            type='text'
                            placeholder='cpf/cnpj'
                            mask={[/\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/]}
                            onChange={e=>setcpfFormat(e.target.value)}
                            guide={false}
                            />
                        </div>
                    }
                    <div style={{display:"flex", minWidth:"100px"}}>
                        <div className='listaFlexCol' style={{minWidth:"100px"}}>
                            <span style={{color: !playmodal.playModalEditar && "yellow"}}>Chave Pix:</span>
                            <input
                            style={{textTransform:"lowercase"}}
                            disabled={disabledEven}
                            type="text"
                            placeholder='chave pix'
                            value={evento.chavePix} 
                            onChange={(e)=>SetEvento("chavePix",e.target.value)}
                            />
                        </div>
                        {!evento.id_Fornecedor &&
                        <div className='listaFlexCol' style={{flex:"0", justifyContent:"end", marginLeft:"5px" , marginBottom:"5px", maxWidth:"40px"}}>
                            <button 
                            disabled={disabledEven}
                            type='button' 
                            onClick={()=>AddFornecedor()}>add
                            </button>
                        </div>
                        }
                    </div>
                    </>
                    :
                    evento.id_Fornecedor &&
                    <>
                    <div className='listaFlexCol'>
                        <span className='titulo'> Fornecedor: </span>
                        <span 
                        ref={FornecedorRef}
                        style={{cursor:"pointer"}}
                        className='tituloHover' 
                        onClick={()=>EditarSelect("RefFornecedor")}>
                            {evento.fornecedor}
                        </span>  
                    </div>
                    <div className='listaFlexCol'>
                        <span className='titulo'>Chave Pix: </span>
                        <span style={{textTransform:"lowercase", color:"white"}}>{evento.chavePix}</span>  
                    </div>
                    </>
                    }
                </S.box1>
            </S.div3>

            {/*---------inputs----------- */}
            <S.div3>
                {(evento.id_Fornecedor || playmodal.playModalEditar) &&<>
                <S.box1>
                    <div className='listaFlexCol'>
                        <span>Data Evento: </span>
                        <input 
                        disabled={disabledEven}
                        type="date"
                        placeholder='Data Evento' 
                        value={evento.dataEvento && parametroInit.editModal? format(new Date(evento.dataEvento), 'yyyy-MM-dd') : evento.dataEvento}
                        onChange={e=>SetEvento('dataEvento',e.target.value)}
                        />
                    </div>
                    <div className='listaFlexCol'>
                        <span>Tipo Evento:</span>
                        <select
                        disabled={disabledEven}
                        value={evento.tipoEvento}
                        onChange={e=>SetEvento('tipoEvento', e.target.value)} 
                        required
                        >
                            {parametroInit.editModal &&
                            <option>{evento.tipoEvento}</option>
                            }
                            <option></option>
                            <option value="Guincho_Mecanica">Guincho P.Mecanica</option>
                            <option value="Guincho_Eletrica">Guincho P.Eletrica</option>
                            <option value="Guincho_Colisao">Guincho Colisao</option>
                            <option value="Vidro_part">Quebra Vidro</option>
                            <option value="Lanterna_part">Quebra Lanterna</option>
                            <option value="Retrovisor_part">Quebra Retrovisor</option>
                            <option value="Reparos_part">Pequeno Reparo</option>
                            <option value="Colisao_part">Colisão</option>
                            <option value="Roubo">Roubo Veiculo</option>
                            <option value="Colisao_PT">Perca Total</option>
                            <option value="Incendio_PT">Incendio</option>
                        </select>
                    </div>

                    <div className='listaFlexCol'>
                        <span>Nota/NF/BO:</span>
                        <div style={{display:"flex"}}>
                            <input 
                            disabled={disabledEven}
                            type="text"
                            className='uppercase'
                            placeholder='n°' 
                            value={evento.ocorrenciaBO}
                            onChange={e=>SetEvento('ocorrenciaBO',e.target.value)}
                            />
                            <img 
                            src={icon_addArquivo} 
                            onClick={()=>!disabledEven? SelectFile():""} 
                            style={{width:"25px", cursor:!disabledEven?"pointer":"not-allowed"}}/>
                        </div>
                    </div>
                </S.box1>  
                
                <S.boxFiles>
                    <AddArquivos/>
                </S.boxFiles>  
                
                <S.box1>
                    {/guincho/i.test(evento.tipoEvento) && <>
                    <div className='listaFlexCol' style={{minWidth:"150px"}}>
                        <span>Origem:</span>
                        <input 
                        disabled={disabledEven}
                        value={evento.origem}
                        className='maiusculo'
                        type='text' 
                        placeholder='cidade/bairro'
                        onChange={e=>SetEvento('origem', e.target.value)}
                        />
                    </div>
                    <div className='listaFlexCol' style={{minWidth:"150px"}}>
                        <span>Destino:</span>
                        <input 
                        disabled={disabledEven}
                        value={evento.destino}
                        className='maiusculo'
                        type='text' 
                        placeholder='cidade/bairro'
                        onChange={e=>SetEvento('destino', e.target.value)}
                        />
                    </div>
                    </>}
                    {evento.tipoEvento &&
                    <div className='listaFlexCol' style={{maxWidth:"100px"}}>
                        <span>Valor Indenização:</span>
                        <input
                        disabled={disabledEven}
                        type="text"
                        value={evento.orcamento} 
                        onChange={(e)=>SetEvento("orcamento",e.target.value)}
                        />
                    </div>
                    }
                </S.box1>

                <S.box2>
                    {/colisao/i.test(evento.tipoEvento) &&
                    <div style={{'display':"flex","minWidth":"100px", 'height':"30px"}}>
                        <span style={{'display':"flex", 'alignItems':"center"}}>Teve Terceiro</span>
                        <input 
                        checked={teveTerceiro} 
                        className='terceiroCheckbox'
                        disabled={disabledEven}
                        type="checkbox"
                        onChange={(e)=>funcCheckBox(e)}
                        />
                    </div>
                    }
                    
                    {teveTerceiro &&  <>
                    <div>
                        <span>Tipo:</span>
                        <select
                        disabled={disabledEven}
                        value={terceiro.tipo}
                        onChange={e=>SetEventoTerc('tipo', e.target.value)} 
                        required>
                            <option></option>
                            <option>Motocicleta</option>
                            <option>Automovel</option>
                            
                        </select>
                    </div>
                    </>}
                </S.box2>
                {teveTerceiro &&
                <S.box3>
                    <div>
                        <span>Placa Terceiro:</span>
                        <MaskedInput
                        disabled={disabledEven}
                        value={terceiro.placa}
                        className='uppercase'
                        type='text'
                        placeholder='placa'
                        mask={[/\D/, /\D/, /\D/,/\d/,/\w/,/\d/, /\d/]}
                        onChange={e=>SetEventoTerc('placa', e.target.value)}
                        guide={false}
                        />
                    </div>
                    
                    <div>
                        <span>Chassi:</span>
                        <input 
                        disabled={disabledEven}
                        value={terceiro.chassi}
                        className='uppercase'
                        type='text' 
                        placeholder='chassi'
                        onChange={e=>SetEventoTerc('chassi', e.target.value)}
                        />
                    </div>
                    <div>
                        <span>Ano Modelo:</span>
                        <input 
                        disabled={disabledEven}
                        value={terceiro.anoMod}
                        className='maiusculo'
                        type='text' 
                        placeholder='ano'
                        onChange={e=>SetEventoTerc('anoMod', e.target.value)}
                        />
                    </div>
                    {/* <div>
                        <span>Montadora:</span>
                        <input 
                        disabled={disabledEven}
                        value={terceiro.montadora}
                        className='maiusculo'
                        type='text' 
                        placeholder='montadora'
                        onChange={e=>SetEventoTerc('montadora', e.target.value)}
                        />
                    </div> */}
                    <div>
                        <span>Modelo:</span>
                        <input 
                        disabled={disabledEven}
                        value={terceiro.modelo}
                        className='maiusculo'
                        type='text' 
                        placeholder='modelo'
                        onChange={e=>SetEventoTerc('modelo', e.target.value)}
                        />
                    </div>
                    <div>
                        <span>Valor Fipe:</span>
                        <input 
                        disabled={disabledEven}
                        value={terceiro.valorFipe}
                        type='text' 
                        placeholder='valor'
                        onChange={e=>SetEventoTerc('valorFipe', e.target.value)}
                        />
                    </div>
                
                    {/* <S.box1>
                        <div>
                            <span>Anexar CNH Terceiro:</span>
                            <input 
                            disabled={disabledEven}
                            type="file"
                            className='maiusculo'
                            value={terceiro.anexoCNH}
                            onChange={e=>SetEventoTerc('anexoCNH',e.target.value)}
                            />
                        </div>
                    </S.box1> */}
                </S.box3>
                }

                <textarea 
                disabled={disabledEven}
                rows={5} 
                placeholder='Detalhes da Ocorrencia:' 
                value={evento.descricao}
                onChange={e=>SetEvento('descricao',e.target.value)}
                />

                <S.divButton editModal={editModal}>
                    {editModal?
                        <button 
                        type='button' 
                        onClick={Atualizar}>Atualizar</button>
                    :
                        <button 
                        type='button' 
                        onClick={AddEvento}>Salvar</button>
                    }
                </S.divButton>

                </>}
            </S.div3>
        </S.Container>
    </S.body>
  );
}

export default ModalEvento;
