const size = {
    sm: '600px',
    md: '860px',
    bg: '1250px'
};

const breakpoints = {
    sm: `(max-width:${size.sm})`,
    md: `(max-width:${size.md})`,
    bg: `(max-width:${size.bg})`,
};

const coresPadrao = {
    corPretoClaro: '#262626',
    corFundoPreto: 'black',
    corFundoBranco: 'white',
    
}

export {breakpoints, coresPadrao};