import styled from 'styled-components';
import {coresPadrao} from '../../breakpoints';


export const politica = styled.div`
display:flex;
width:100vw;
justify-content: center ;
margin:0 ;


body{
    display: flex ;
    width: clamp(450px, 80vw, 1000px);
    padding:5px ;
    font-size:13px;
    border: 2px solid #3881ff;
    flex-direction: column;
    
}
a{
    text-decoration: none ;
    color: #3881ff;
    font-weight:600 ;
}
span{
    color: rgb(68, 68, 68) ;
}
`